import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { buildFiltersUrl } from 'state-domains/network';
import {
    addQueryParams,
    sendCreateRequest,
    sendUpdateRequest,
    sendDeleteRequest,
    sendRetrieveRequest,
} from 'state-domains/utils';

import { FiltersPayload, FilterQueryProps } from '../types';

import {
    buildTopic,
    translateFilterList,
    translateFilter,
    getRequestOverrides,
} from './translators';
import { APIAllFilters, APITopic } from './types';

function handleLoadFilters(response: APIAllFilters): Observable<FiltersPayload> {
    const results = translateFilterList(response);
    return of(results);
}

export function loadFilters(token: string, audienceId: string): Observable<FiltersPayload> {
    let url = buildFiltersUrl(audienceId);
    // add a large number limit so that all items are returned.
    // It would be safer to check the response for a null next link however it would be even more efficient
    // to filter by central instance id resource.
    // in the mean time we have this hack
    url = addQueryParams(url, { limit: '10000' });
    const ajax = sendRetrieveRequest(url, getRequestOverrides(token));
    return ajax.pipe(switchMap(({ response }) => handleLoadFilters(response)));
}

function handleCreateFilter(response: APITopic): Observable<FiltersPayload> {
    const result = translateFilter(response);
    return of({ filters: [result] });
}

export function createFilter(
    token: string,
    audienceId: string,
    props: FilterQueryProps,
    enabled: boolean,
): Observable<FiltersPayload> {
    const payload = buildTopic(props, enabled);
    const url = buildFiltersUrl(audienceId);
    const ajax = sendCreateRequest(url, payload, 'json', getRequestOverrides(token));
    return ajax.pipe(switchMap(({ response }) => handleCreateFilter(response)));
}

export function updateFilter(
    token: string,
    url: string,
    enabled: boolean,
): Observable<FiltersPayload> {
    const ajax = sendUpdateRequest(url, { enabled }, getRequestOverrides(token));
    return ajax.pipe(switchMap(({ response }) => handleCreateFilter(response)));
}

export function deleteFilter(token: string, url: string): Observable<boolean> {
    const ajax = sendDeleteRequest(url, undefined, getRequestOverrides(token));
    return ajax.pipe(switchMap(() => of(true)));
}
